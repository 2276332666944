<!-- 单科考试编辑 -->
<template>
  <div class="editSingle">
    <!-- 上面部分 -->
    <div class="top">
      <span style="font-size: 16px; font-weight: bold">单科考试编辑</span>
      <el-button class="el-btn" size="small" @click="goBack"> 返回 </el-button>
    </div>
    <!-- 表单部分 -->
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      id="form-1"
      class=""
      inline
      label-width="150px"
    >
      <el-form-item label="编码">
        <el-input v-model="form.coded" placeholder="" disabled></el-input>
      </el-form-item>
      <el-form-item label="考试日期" prop="testDate">
        <el-date-picker
          v-model="form.testDate"
          type="date"
          placeholder="选择考试日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="状态">
        <div style="width: 300px">
          <el-radio-group v-model="form.condition">
            <el-radio :label="1">准备</el-radio>
            <el-radio :label="2">完成</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item label="选择科目">
        <el-select
          v-model="form.subject"
          placeholder="请选择科目"
          :clearable="true"
        >
          <el-option
            v-for="item in form.subjectChoose"
            :key="item.id"
            :label="item.course_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择等级">
        <el-select
          v-model="form.grade"
          placeholder="请选择等级"
          :clearable="true"
        >
          <el-option :label="0" :value="0"></el-option>
          <el-option
            v-for="item in form.gradeChoose"
            :key="item.id"
            :label="item.level"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="考试大类">
        <el-select
          v-model="form.exam"
          filterable
          placeholder="请选择考试大类"
          :clearable="true"
        >
          <el-option
            v-for="item in form.examOpt"
            :key="item.id"
            :label="item.exam_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="考试名称" prop="testName">
        <el-input
          v-model="form.testName"
          placeholder="请输入考试名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="开始时间" prop="startTime">
        <el-time-picker
          value-format="HH:mm"
          format="HH:mm"
          v-model="form.startTime"
          :picker-options="{
            selectableRange: '00:00:00 - 23:59:59',
          }"
          placeholder="请选择开始时间"
        >
        </el-time-picker>
      </el-form-item>
      <el-form-item label="截止时间" prop="endTime">
        <el-time-picker
          value-format="HH:mm"
          format="HH:mm"
          v-model="form.endTime"
          :picker-options="{
            selectableRange: '00:00:00 - 23:59:59',
          }"
          placeholder="请选择截止时间"
        >
        </el-time-picker>
      </el-form-item>
      <el-form-item label="考试时长" prop="duration">
        <el-input
          v-model="form.duration"
          placeholder="请输入考试时长"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="收费标准" prop="charges">
                <el-input v-model="form.charges" placeholder="请输入收费标准"></el-input>
            </el-form-item> -->
      <el-form-item label="监考老师" prop="invigilator">
        <el-input
          v-model="form.invigilator"
          placeholder="请输入监考老师"
        ></el-input>
      </el-form-item>
      <el-form-item label="计划考试人数">
        <el-input
          v-model="form.project"
          placeholder="请输入计划考试人数"
        ></el-input>
      </el-form-item>
      <el-form-item label="实际考生人数">
        <el-input
          v-model="form.actual"
          placeholder="请输入实际考生人数"
        ></el-input>
      </el-form-item>
      <el-form-item label="说明">
        <el-input
          v-model="form.explain"
          placeholder="请输入"
          type="textarea"
          class="textArea-width"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <!-- 底部 提交与取消 -->
    <div slot="footer" class="dialog-footer">
      <el-button
        style="background-color: #18bc9c; color: #ffffff"
        @click="submitForm('form')"
        >提 交</el-button
      >
      <el-button class="btn" @click="resetForm('form')">取 消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        coded: "", //编码
        testDate: "", //考试日期
        condition: 1, //状态
        subject: "", //科目
        subjectChoose: [], //科目选择
        grade: "", //等级
        gradeChoose: [], //等级选择
        testName: "", //考试名称
        startTime: "", //开始时间
        endTime: "", //截止时间
        duration: "", //考试时长
        charges: "", //收费标准
        invigilator: "", //监考老师
        project: "", //计划考试人数
        actual: "", //实际考生人数
        exam: "", //考试大类
        examOpt: [], //考试大类选项，
        explain: "", //说明
      },
      rules: {
        testDate: [
          { required: true, message: "考试日期不能为空", trigger: "change" },
        ],
        testName: [
          { required: true, message: "考试名称不能为空", trigger: "blur" },
        ],
        startTime: [
          { required: true, message: "开始时间不能为空", trigger: "change" },
        ],
        endTime: [
          { required: true, message: "截止时间不能为空", trigger: "change" },
        ],
        duration: [
          { required: true, message: "考试时长不能为空", trigger: "blur" },
        ],
        charges: [
          { required: true, message: "收费标准不能为空", trigger: "blur" },
        ],
        invigilator: [
          { required: true, message: "监考老师不能为空", trigger: "blur" },
        ],
      },
      bool: false,
    };
  },
  created() {
    //科目数据
    this.$request({
      url: "/api/course/list",
      method: "POST",
      data: {
        page: 1,
        limit: 100,
      },
    }).then((res) => {
      this.form.subjectChoose = res.data.list;
    });

    //等级数据
    this.$request({
      url: "/api/level/list",
      method: "POST",
      data: {
        page: 1,
        limit: 100,
      },
    }).then((res) => {
      console.log(res.data.list);
      this.form.gradeChoose = res.data.list;
    });

    // 初次渲染
    this.$request({
      url: "/api/Exam/detail",
      method: "POST",
      data: {
        id: this.$route.query.id,
      },
    }).then((res) => {
      if (res.code == 1) {
        console.log(res, "详情");
        let data = res.data;
        let form = this.form;
        form.coded = data.number; //编码
        form.testDate = data.exam_date; //考试日期
        form.condition = data.status; //状态
        form.subject = data.course_id; //科目
        form.grade = data.level_id; //等级
        form.testName = data.exam_name; //考试名称
        form.startTime = data.start_date; //开始时间
        form.endTime = data.end_date; //截止时间
        form.duration = data.exam_duration; //考试时长
        form.charges = data.price; //收费标准
        form.invigilator = data.invigilator; //监考老师
        form.project = data.exam_num; //计划考试人数
        form.actual = data.exam_num2; //实际考生人数
        form.exam = Number(data.apply_category); // 考试大类
        form.explain = data.remark; //说明
      }
    });
    // 考试大类
    this.$request({
      url: "/api/ExamCategory/list",
      method: "POST",
      data: {
        page: 1,
        limit: 1000,
      },
    }).then((res) => {
      // console.log(res,"考试大类")
      if (res.code == 1) {
        this.form.examOpt = res.data.list;
      }
    });
  },
  methods: {
    goBack() {
      //返回
      this.$router.go(-1);
    },
    submitForm(form) {
      //提交
      this.$refs[form].validate((valid) => {
        if (valid) {
          // alert('submit!');
          // console.log(this.form)
          if (this.bool) {
            return;
          }
          this.bool = true;
          this.$request({
            url: "/api/Exam/edit",
            method: "POST",
            data: {
              number: this.form.coded,
              status: this.form.condition,
              exam_date: this.form.testDate,
              course_id: this.form.subject,
              level_id: this.form.grade,
              exam_name: this.form.testName,
              start_date: this.form.startTime,
              end_date: this.form.endTime,
              exam_duration: this.form.duration,
              price: this.form.charges,
              invigilator: this.form.invigilator,
              exam_num: this.form.project,
              exam_num2: this.form.actual,
              remark: this.form.explain,
              apply_category: this.form.exam,
              id: this.$route.query.id,
            },
          })
            .then((res) => {
              // console.log(res)
              if (res.code == 1) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                setTimeout(() => {
                  this.$router.go(-1);
                }, 1500);
              } else {
                this.$message({
                  message: res.msg,
                  type: "error",
                });
                setTimeout(() => {
                  this.$router.go(-1);
                }, 1500);
              }
            })
            .catch(() => {
              this.bool = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(form) {
      //取消
      this.goBack();
      this.$refs[form].resetFields();
    },
  },
};
</script>

<style scoped="scoped">
.editSingle {
  width: 100%;
  height: 100%;
}
/* 上面部分 */
.top {
  padding-top: 25px;
  margin: 0px 24px 30px 28px;
  display: flex;
  justify-content: space-between;
}

.el-btn {
  background: #18bc9c;
  padding-left: 25px;
  background-position: 5px 7px;
  display: inline-block;
  background-image: url(../../assets/back.png);
  background-repeat: no-repeat;
  color: #ffffff;
}
/* 表单部分 */
.el-form {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}

::v-deep .el-form-item__label {
  color: #000000;
}

.el-input {
  width: 300px;
  height: 37px !important;
}

.el-select {
  width: 300px;
}
::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #ffffff;
}
/* 选择按钮 */
#form-1 ::v-deep .el-radio__input.is-checked .el-radio__inner {
  background-color: #18bc9c;
}

#form-1 ::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #18bc9c;
}

#form-1 ::v-deep .el-radio__inner:hover {
  border-color: #18bc9c;
}
/* 底部提交 取消 */
.dialog-footer {
  text-align: center;
  padding-bottom: 20px;
}
.btn:hover {
  color: #666;
  border-color: #ccc;
  background-color: #ffffff;
}
</style>
